import PopupMessage from '../PopupMessage';
import FooterAbout from './FooterAbout';
import FooterContact from './FooterContact';
import FooterLinks from './FooterLinks';
import FooterRegulations from './FooterRegulations';

const Footer = () => {
  return (
    <>
      <FooterAbout />
      <FooterLinks />
      <FooterRegulations />
      <FooterContact />
      <PopupMessage />
    </>
  );
};

export default Footer;
