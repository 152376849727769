import { useQuery } from "@tanstack/react-query";
import CategoryType from "../types/CategoryType";
import ApiError from "./ApiError";
import fetchFromApi from "./fetchFromApi";
import ArticleType from "../types/ArticleType";
import ProductType from "../types/ProductType";

const CATEGORY_QUERY_KEY = "category";
const CATEGORY_ARTICLES_QUERY_KEY = "category-articles";
const CATEGORY_BESTSELLERS_QUERY_KEY = "category-bestsellers";
const BASE_CATEGORIES_QUERY_KEY = "base-categories";
interface BaseCategoriesResponse {
  categories: CategoryType[];
}

interface CategoryResponse {
  category: CategoryType;
}

interface CategoryArticlesResponse {
  articles: ArticleType[];
}

interface CategoryBestsellersResponse {
  products: ProductType[];
}

export const useGetBaseCategoriesQuery = () => {
  return useQuery<unknown, ApiError, BaseCategoriesResponse>({
    queryKey: [BASE_CATEGORIES_QUERY_KEY],
    queryFn: () => fetchFromApi("/base-categories"),
  });
};

export const useGetCategoryQuery = (categoryId: string) => {
  return useQuery<unknown, ApiError, CategoryResponse>({
    queryKey: [CATEGORY_QUERY_KEY, { categoryId }],
    queryFn: () => fetchFromApi(`/categories/${categoryId}`),
  });
};

export const useGetCategoryArticlesQuery = (categoryId: string, limit = 3) => {
  return useQuery<unknown, ApiError, CategoryArticlesResponse>({
    queryKey: [CATEGORY_ARTICLES_QUERY_KEY, { categoryId, limit }],
    queryFn: () =>
      fetchFromApi(`/categories/${categoryId}/articles?limit=${limit}`),
  });
};
export const useGetCategoryBestsellersQuery = (category: CategoryType) => {
  return useQuery<unknown, ApiError, CategoryBestsellersResponse>({
    queryKey: [CATEGORY_BESTSELLERS_QUERY_KEY, category.id],
    queryFn: () => fetchFromApi(`/categories/${category.id}/bestsellers`),
  });
};
