import { useQuery } from "@tanstack/react-query";
import FooterTextBlockType from "../types/FooterTextBlockType";
import ApiError from "./ApiError";
import fetchFromApi from "./fetchFromApi";

const FOOTER_TEXT_BLOCKS_QUERY_KEY = "footerTextBlocks";

interface FooterTextBlockResponse {
  footer_text_blocks: FooterTextBlockType[];
}


export const useGetFooterTextBlocksQuery = () => {
  return useQuery<FooterTextBlockResponse, ApiError, FooterTextBlockResponse>({
    queryKey: [FOOTER_TEXT_BLOCKS_QUERY_KEY],
    queryFn: () => fetchFromApi("/footer-text-blocks"),
  });
};

