import './FooterRegulations.scss';

const FooterRegulations = () => {
  return (
    <div className="footerRegulations">
      <div className="container">
        <h3 className='footerRegulations-heading'>The important part</h3>
        <ul className="footerRegulations-links">
          <li>
            <a href="/s/terms-conditions">Terms & Conditions</a>
          </li>
          <li>
            <a href="/s/privacy-policy-and-gdpr">Privacy Policy</a>
          </li>
          <li>
            <a href="/s/cookies">Cookies Policy</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FooterRegulations;
