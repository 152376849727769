import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import ApiError from "./ApiError";
import fetchFromApi from "./fetchFromApi";

export interface SubscriberParams {
  name: string;
  email: string;
}

export const useCreateSubscriberMutation = (
  options?: Omit<
    UseMutationOptions<SubscriberParams, ApiError, SubscriberParams>,
    "mutationFn"
  >
) => {
  return useMutation({
    ...options,
    mutationFn: (subscriber) => fetchFromApi(`/newsletter/subscribe`, "POST", { subscriber }),
  });
};