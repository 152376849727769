import ReactDOM from "react-dom/client";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/Footer/Footer";
import AppRouter from "./components/Navigation/AppRouter";
import HomeNewsletter from "./components/Newsletter/HomeNewsletter";
import "./styles/react_app.scss";

document.addEventListener("DOMContentLoaded", () => {
  const queryClient = new QueryClient();
  
  const appNode = document.getElementById("app_root") as HTMLElement;
  if (appNode) {
    const appRoot = ReactDOM.createRoot(appNode);
    appRoot.render(<AppRouter />);
  }

  const homeNewsletterNode = document.getElementById(
    "home_newsletter_root"
  ) as HTMLElement;
  if (homeNewsletterNode) {
    const homeNewsletterRoot = ReactDOM.createRoot(homeNewsletterNode);
    homeNewsletterRoot.render(<HomeNewsletter />);
  }

  const footerNode = document.getElementById("page_footer") as HTMLElement;
  if (footerNode) {
    const footerRoot = ReactDOM.createRoot(footerNode);
    footerRoot.render(
      <QueryClientProvider client={queryClient}>
        <Footer />
      </QueryClientProvider>
    );
  }
});
