import classNames from "classnames";
import { RotatingLines } from "react-loader-spinner";

import "./Loader.scss";

interface LoaderProps {
  loading?: boolean;
  full?: boolean;
  className?: string;
}
const Loader = ({ loading = true, full = false, className }: LoaderProps) => {
  if (!loading) return null;

  return (
    <div className={classNames("loader", className, { fixed: full })}>
      <RotatingLines
        strokeColor="grey"
        strokeWidth="5"
        animationDuration="0.75"
        width="96"
        visible={true}
      />
    </div>
  );
};
export default Loader;
