import { useGetFooterTextBlocksQuery } from '../../api/footerTextBlocksApi';
import Loader from '../Loader';

import './FooterAbout.scss';

const FooterAbout = () => {
  const { data: footerTextBlocks, isLoading, error } = useGetFooterTextBlocksQuery();

  const currentPath = window.location.pathname;

  if (currentPath === '/' || currentPath === '/login' || currentPath.startsWith('/catalog/') || currentPath.startsWith('/product/')) {
    return null;
  }

  return (
    <div className="footerAbout">
      <div className="container">
        {error !== null && (
          <div className="footerAbout-error">{error.message}</div>
        )}
        <Loader className="footerAbout-loader" loading={isLoading} />
        {error === null && !isLoading && (
          <>
            <h3 className='footerAbout-heading'>About</h3>
            <div className="footerAbout-columns">
              {footerTextBlocks?.footer_text_blocks.map((block) => (
                <div
                  key={block.id}
                  className="footerAbout-column"
                  dangerouslySetInnerHTML={{ __html: block.content }}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FooterAbout;
