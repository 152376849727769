import { zodResolver } from '@hookform/resolvers/zod';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import ApiError from '../../api/ApiError';
import { useCreateSubscriberMutation } from '../../api/subscribersApi';
import { NewsletterFormParams, newsletterFormSchema } from '../../schemas/newsletterFormSchema';
import CheckBox from '../FormControls/CheckBox';
import TextInput from '../FormControls/TextInput';
import './HomeNewsletter.scss';

const queryClient = new QueryClient();

const HomeNewsletter = () => {
  const { control, handleSubmit, reset, setError } = useForm<NewsletterFormParams>({
    defaultValues: {
      name: '',
      email: '',
      acceptTerms: false,
    },
    resolver: zodResolver(newsletterFormSchema),
  });

  const { mutate: createSubscriber } = useCreateSubscriberMutation();

  const onSubmit = (data: NewsletterFormParams) => {
    createSubscriber(data, {
      onSuccess: () => {
        reset({ name: '', email: '' });
      },
      onError: (error: ApiError) => {
        if (error.statusCode === 422) {
          Object.entries(error.errors || {}).forEach(([name, message]) => {
            setError(name as keyof NewsletterFormParams, {
              message: Array.isArray(message) ? message.join(", ") : message,
            });
          });
        }
      },
    });
  };

  return (
    <div className="homeNewsletter">
      <div className="container">
        <div className="homeNewsletter-wrapper">
          <div className="homeNewsletter-text">
            <div className="homeNewsletter-heading">
              Sign up and <strong>get 20%</strong> off
              <br />
              Your first order
            </div>

            <form className="homeNewsletter-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="homeNewsletter-inputWrapper">
                <TextInput
                  control={control}
                  name="name"
                  placeholder="First name"
                  tabIndex={1}
                />
              </div>

              <div className="homeNewsletter-inputWrapper">
                <TextInput
                  control={control}
                  name="email"
                  placeholder="Enter your email"
                  type="email"
                  tabIndex={2}
                />
                <button type="submit" className="homeNewsletter-emailSubmit" tabIndex={4}></button>
              </div>

              <CheckBox
                control={control}
                name="acceptTerms"
                label={
                  <>
                    I agree to the{' '}
                    <a href="/s/terms-conditions" target="_blank">
                      Terms and Conditions
                    </a>
                  </>
                }
                tabIndex={3}
              />
            </form>
            <div className="homeNewsletter-description">
              Subscribe to our newsletter and we will shop 20% discount code today.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const HomeNewsletterWithQueryClient = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <HomeNewsletter />
    </QueryClientProvider>
  );
};

export default HomeNewsletterWithQueryClient;
