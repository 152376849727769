import { useGetBaseCategoriesQuery } from '../../api/categoriesApi';
import './FooterLinks.scss';

const FooterLinks = () => {
  const { data: baseCategoriesData } = useGetBaseCategoriesQuery();
  const baseCategories = baseCategoriesData?.categories;

  return (
    <div className="footerLinks">
      <div className="container">
        <div className="footerLinks-columns">
          <div className="footerLinks-links">
            <div className="footerLinks-column">
              <h3 className="footerLinks-heading">Category</h3>
              <ul>
                {baseCategories?.map((category) => (
                  <li key={category.id}>
                    <a href={`/catalog/${category.slug}`}>{category.name}</a>
                  </li>
                ))}
                <li><a href="/articles">Articles</a></li>
                <li><a href="/producers">All brands</a></li>
                <li><a href="/contact">Contact</a></li>
              </ul>
            </div>
            <div className="footerLinks-column">
              <h3 className="footerLinks-heading">Good to know</h3>
              <ul>
                <li><a href="/s/about-us">Our Story</a></li>
                <li><a href="/s/mission">Our Mission</a></li>
                <li><a href="/s/customer-service">Sustainability</a></li>
                <li><a href="/s/product-aftercare">Product aftercare</a></li>
              </ul>
            </div>
            <div className="footerLinks-column">
              <h3 className="footerLinks-heading">Support</h3>
              <ul>
                <li><a href="/s/delivery">Delivery</a></li>
                <li><a href="/s/returns">Returns</a></li>
                <li><a href="/s/faq">FAQs</a></li>
                <li><a href="/s/warranty">Warranty</a></li>
              </ul>
            </div>
          </div>
          <ul className="footerLinks-icons">
            <li>
              <span className="icon-insurrance"></span>
              Safe parcel
            </li>
            <li>
              <span className="icon-shipment"></span>
              Courier delivery
            </li>
            <li>
              <span className="icon-returns"></span>
              14 days returns
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FooterLinks;
