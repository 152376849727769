import { OrderParams, useCreateOrderMutation } from "../../api/ordersApi";
import BasketType from "../../types/BasketType";
import ReactGooglePayButton from "@google-pay/button-react";
import Loader from "../Loader";
import { toast } from "react-toastify";
import ecommerceGA from "../../utils/ecommerceGA";

interface GooglePayButtonProps {
  basket: BasketType;
}

const stripePublishableKey: string = process.env.STRIPE_PUBLISHABLE_KEY;

const GooglePayButton = ({ basket }: GooglePayButtonProps) => {
  if (basket === undefined) {
    return null;
  }

  const { mutate: createOrder, isPending: createOrderIsPending } =
    useCreateOrderMutation();

  const handlCreateOrder = (
    paymentRequest: google.payments.api.PaymentData
  ) => {
    const params: OrderParams = {
      full_name: paymentRequest.shippingAddress?.name ?? "",
      company_name: "",
      company_number: "",
      address_line1: paymentRequest.shippingAddress?.address1 ?? "",
      address_line2: paymentRequest.shippingAddress?.address2 ?? "",
      zipcode: paymentRequest.shippingAddress?.postalCode ?? "",
      city: paymentRequest.shippingAddress?.locality ?? "",
      phone_number: paymentRequest.shippingAddress?.phoneNumber ?? "",
      email: paymentRequest.email ?? "",
      details: "",
      custom_delivery_address: true,
      delivery_full_name: paymentRequest.shippingAddress?.name ?? "",
      delivery_company_name: "",
      delivery_company_number: "",
      delivery_address_line1: paymentRequest.shippingAddress?.address1 ?? "",
      delivery_address_line2: paymentRequest.shippingAddress?.address2 ?? "",
      delivery_zipcode: paymentRequest.shippingAddress?.postalCode ?? "",
      delivery_city: paymentRequest.shippingAddress?.locality ?? "",
      delivery_phone_number: paymentRequest.shippingAddress?.phoneNumber ?? "",
      delivery_email: paymentRequest.email ?? "",
      delivery_details: paymentRequest.shippingAddress?.address3 ?? "",
      accept_terms: true,
    };
    const stripeToken = JSON.parse(
      paymentRequest.paymentMethodData.tokenizationData.token
    );
    params.stripe_token_id = stripeToken.id;
    if (stripeToken.card) {
      params.address_line1 = stripeToken.card.address_line1;
      params.address_line2 = stripeToken.card.address_line2;
      params.zipcode = stripeToken.card.address_zip;
      params.city = stripeToken.card.address_city;
      params.full_name = stripeToken.card.name;
    }

    createOrder(params, {
      onSuccess: ({ order, payment_url }) => {
        ecommerceGA.purchase(order);
        ecommerceGA.addShippingInfo(order);
        ecommerceGA.addPaymentInfo(order, "Google Pay");
        if (payment_url !== undefined) {
          window.location.href = payment_url;
        } else {
          window.location.href = `/my-order/${order.id}/${order.secret_code}`;
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });
  };

  return (
    <>
      <Loader loading={createOrderIsPending} full />
      <ReactGooglePayButton
        environment={__DEV__ ? "TEST" : "PRODUCTION"}
        paymentRequest={{
          apiVersion: 2,
          apiVersionMinor: 0,
          allowedPaymentMethods: [
            {
              type: "CARD",
              parameters: {
                allowedAuthMethods: ["PAN_ONLY"],
                allowedCardNetworks: ["MASTERCARD", "VISA"],
              },
              tokenizationSpecification: {
                type: "PAYMENT_GATEWAY",
                parameters: {
                  gateway: "stripe",
                  "stripe:version": "2018-10-31",
                  "stripe:publishableKey": stripePublishableKey,
                },
              },
            },
          ],
          merchantInfo: {
            merchantId: "BCR2DN4TUTMOFLR6",
            merchantName: "Red Damson",
          },
          transactionInfo: {
            totalPriceStatus: "FINAL",
            totalPriceLabel: "Total",
            totalPrice: Number(basket.total_price).toFixed(2),
            currencyCode: "GBP",
            countryCode: "UK",
          },
          emailRequired: true,
          shippingAddressRequired: true,
          shippingAddressParameters: {
            phoneNumberRequired: true,
            allowedCountryCodes: ["GB", "PL"],
          },
        }}
        onLoadPaymentData={(paymentRequest) => {
          handlCreateOrder(paymentRequest);
        }}
        buttonType="plain"
        buttonSizeMode="fill"
        style={{ height: 55, width: 180 }}
      />
    </>
  );
};
export default GooglePayButton;
