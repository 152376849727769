import { z } from "zod";

export const newsletterFormSchema = z.object({
  name: z.string(),
  email: z.string().email(),
  acceptTerms: z.boolean().refine((data) => data === true, {
    message: "You must accept the terms and conditions",
  }),
});

export type NewsletterFormParams = z.infer<typeof newsletterFormSchema>;
