import { faFacebook, faInstagram, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetSettingsQuery } from '../../api/settingsApi';
import Loader from '../Loader';

import './FooterContact.scss';

const FooterContact = () => {
  const { data: settingsData, isLoading } = useGetSettingsQuery();
  const settings = settingsData?.settings;

  return (
    <div className="footerContact">
      <Loader loading={isLoading} className="footerContact-loader" />

      {settings !== undefined && (
        <div className="container">
          <div className="footerContact-columns">
            <div className="footerContact-column footerContact-numbers">
              <h3 className="footerContact-heading">{settings.site_name}</h3>

              {settings.site_phone && (
                <>
                  tel: <a href={`tel:${settings.site_phone}`}>{settings.site_phone}</a>
                  <br />
                </>
              )}
              mail: <a href={`mailto:${settings.site_email}`}>{settings.site_email}</a>
            </div>

            <div className="footerContact-column footerContact-socials">
              {settings.instagram_page && (
                <a className="footer-icon" href={settings.instagram_page} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              )}
              {settings.facebook_fanpage && (
                <a className="footer-icon" href={settings.facebook_fanpage} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
              )}
              {settings.tiktok_profile && (
                <a className="footer-icon" href={settings.tiktok_profile} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faTiktok} />
                </a>
              )}
              {settings.youtube_channel && (
                <a className="footer-icon" href={settings.youtube_channel} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
              )}
            </div>

            <div className="footerContact-column footerContact-payments">
              <p className="footerContact-payments-heading">Payments supported by</p>
              <div className="payments-grid">
                <span></span>
                <img src="/assets/payments/visa.png" alt="VISA logo" />
                <img src="/assets/payments/mastercard.png" alt="Mastercard logo" />
                <img src="/assets/payments/paypal.png" alt="PayPal logo" />
                <img src="/assets/payments/klarna.png" alt="Klarna logo" />
                <img src="/assets/payments/gpay.png" alt="Google Pay logo" />
                <img src="/assets/payments/applepay.png" alt="Apple Pay logo" />
                <img src="/assets/payments/stripe.png" alt="Stripe logo" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FooterContact;
